import { FRONT_CHAT_ID } from "src/config/config";

export function showChat(options: { clientName: string; userId: string }) {
  if ("FrontChat" in window) {
    console.log("Front: show");

    // @ts-expect-error
    window.FrontChat("init", {
      chatId: FRONT_CHAT_ID,
      useDefaultLauncher: true,
      name: options.clientName,
      customFields: {
        copilot_user_id: options.userId,
        copilot_dash_link: `https://trainer.trainwell.net/admin/company/clients/${options.userId}`,
      },
    });
  } else {
    console.log("Front: can't show");
  }
}

export function hideChat() {
  if ("FrontChat" in window) {
    console.log("Front: hide");

    // @ts-expect-error
    window.FrontChat("shutdown");
  } else {
    console.log("Front: can't hide");
  }
}
