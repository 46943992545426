import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import type { CoachMatch } from "@trainwell/api-sdk";
import { api } from "src/lib/trainwellApi";

export const getPotentialTrainers = createAsyncThunk(
  "switchTrainer/getPotentialTrainers",
  async (data: { userId: string }) => {
    const response = await api.clients.getCoachMatchScores({
      userId: data.userId,
      changingCoach: true,
      generateJustification: false,
      numberOfCoaches: 99999,
    });

    return response;
  },
);

// Define a type for the slice state
interface SwitchTrainerState {
  forcedTrainerId?: string;
  potentialTrainers: CoachMatch[] | undefined;
  newTrainerId: string | undefined;
}

// Define the initial state using that type
const initialState: SwitchTrainerState = {
  potentialTrainers: undefined,
  newTrainerId: undefined,
};

export const switchTrainerSlice = createSlice({
  name: "switchTrainer",
  initialState,
  reducers: {
    resetSwitchCoach: () => initialState,
    setForcedTrainerID: (state, action: PayloadAction<string>) => {
      const forcedTrainerId = action.payload;

      state.forcedTrainerId = forcedTrainerId;
    },
    setNewTrainerID: (state, action: PayloadAction<string>) => {
      const newTrainerId = action.payload;

      state.newTrainerId = newTrainerId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPotentialTrainers.fulfilled, (state, action) => {
      console.log("Redux: Got potential trainers");

      state.potentialTrainers = action.payload.coach_weights
        .sort((a, b) => (a.score < b.score ? 1 : -1))
        .map(({ trainer }) => {
          return trainer;
        });
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetSwitchCoach, setNewTrainerID, setForcedTrainerID } =
  switchTrainerSlice.actions;

export default switchTrainerSlice.reducer;
