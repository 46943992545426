import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/stores/store";

export const payTip = createAsyncThunk(
  "billing/tipPayment",
  async (tipAmount: number, { getState }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("client_not_found");
    }

    if (
      !state.billing.paymentMethods ||
      state.billing.paymentMethods.length === 0
    ) {
      throw new Error("no_payment_method");
    }
    const paymentMethodId = state.billing.paymentMethods.find(
      (method) => method.is_default,
    )?.id;

    if (!paymentMethodId) {
      throw new Error("no_default_payment");
    }

    const response = await api.clients.makeTipPayment(
      state.client.client.user_id,
      {
        tipAmount,
        paymentMethodId: paymentMethodId,
      },
    );

    if (!response) throw new Error("undefined_response");

    return response;
  },
);

// Define a type for the slice state
interface TippingState {
  paymentStatus: "idle" | "loading" | "succeeded" | "failed";
  paymentError: string | undefined;
}

// Define the initial state using that type
const initialState: TippingState = {
  paymentStatus: "idle",
  paymentError: undefined,
};

export const tippingSlice = createSlice({
  name: "tipping",
  initialState,
  reducers: {
    resetTipping: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(payTip.fulfilled, (state, action) => {
      state.paymentStatus = "succeeded";
    });
    builder.addCase(payTip.pending, (state, action) => {
      state.paymentStatus = "loading";
    });
    builder.addCase(payTip.rejected, (state, action) => {
      state.paymentStatus = "failed";
      state.paymentError = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetTipping } = tippingSlice.actions;

export default tippingSlice.reducer;

export const selectTipPaymentStatus = (state: RootState) =>
  state.tipping.paymentStatus;
export const selectTipPaymentError = (state: RootState) =>
  state.tipping.paymentError;
