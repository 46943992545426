import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/stores/store";

export const startSession = createAsyncThunk(
  "analytics/startSession",
  async (data: { userId: string; forceTest: string[] }, { getState }) => {
    const state = getState() as RootState;

    if (state.analytics.sessionId) {
      throw new Error("Session started already");
    }

    const response = await api.analytics.startSession({
      userId: data.userId,
      platform: "web_account",
      forceTest: data.forceTest,
    });

    if (!response) {
      throw new Error("Error starting analytics session");
    }

    sessionStorage.setItem("sessionID", response.sessionId);

    return response;
  },
);

export const trackEvent = createAsyncThunk(
  "analytics/trackEvent",
  async (data: { event_type: string; event_content?: any }, { getState }) => {
    const state = getState() as RootState;

    const response = await api.analytics.trackEvent({
      userId: state.client.client?.user_id ?? "help",
      sessionId: state.analytics.sessionId ?? "help",
      eventType: data.event_type,
      eventContent: data.event_content,
      platform: "web_account",
    });

    return response;
  },
);

export const fetchActiveTestIds = createAsyncThunk(
  "analytics/fetchActiveTestIds",
  async (
    data: {
      forceTestArray: string[];
      debugTest: string | undefined;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const userId = state.client.client?.user_id;
    const { forceTestArray } = data;

    if (!userId) {
      throw new Error("User Id cannot be undefined");
    }

    // Retrieve active tests from the db
    const activeTestIds = await api.analytics.getActiveTests(
      userId,
      forceTestArray.length > 0 ? forceTestArray : undefined,
    );

    return activeTestIds;
  },
);

// Define a type for the slice state
interface AnalyticsState {
  sessionId?: string;
  activeTests: string[];
}

// Define the initial state using that type
const initialState: AnalyticsState = {
  sessionId: undefined,
  activeTests: [],
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetAnalytics: () => initialState,
    setSessionId: (state, action: PayloadAction<string>) => {
      const sessionID = action.payload;
      console.log("Setting sessionID:", sessionID);

      state.sessionId = sessionID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startSession.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const { sessionId, userId, activeTests } = action.payload;

      console.log("Redux: Started session");
      console.log("user_id: " + userId);
      console.log("session_id: " + sessionId);
      console.log("active tests: " + JSON.stringify(activeTests));

      state.sessionId = sessionId;
      state.activeTests = activeTests;
    });
    builder.addCase(fetchActiveTestIds.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const activeTestIds = action.payload;

      state.activeTests = activeTestIds;
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetAnalytics, setSessionId } = analyticsSlice.actions;

export default analyticsSlice.reducer;
