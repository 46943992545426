import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import type { CalendarEvent } from "@trainwell/api-sdk";
import { ColanderCancel, ColanderLoading } from "@trainwell/colander";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat } from "src/lib/frontChat";
import { api } from "src/lib/trainwellApi";
import { fetchClient } from "src/slices/clientSlice";

export default function CancelPage() {
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const [event, setEvent] = React.useState<CalendarEvent>();
  const client = useAppSelector((state) => state.client.client);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    hideChat();
  });

  useEffect(() => {
    if (eventId) {
      api.events.getOne(eventId).then((data) => {
        setEvent(data);
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (!client && event?.user_id) {
      dispatch(fetchClient(event.user_id));
    }
  }, [dispatch, event?.user_id]);

  if (!eventId) {
    return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            isSmall ? theme.palette.background.paper : "#f6f6f6",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <ColanderLoading
            variant={isSmall ? "box" : "card"}
            message={"Finding event id"}
          />
        </Container>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          isSmall ? theme.palette.background.paper : "#f6f6f6",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md" sx={{ py: isSmall ? undefined : 2 }}>
        <ColanderCancel
          variant={isSmall ? "box" : "card"}
          eventId={eventId}
          api={api}
        />
      </Container>
    </Box>
  );
}
