import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type {
  SubscriptionGroup,
  SubscriptionGroupOption,
} from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/stores/store";
import { setClient } from "./clientSlice";

export const fetchSubscriptionGroup = createAsyncThunk(
  "payment/fetchSubscriptionOptions",
  async (userId: string) => {
    const response = await api.subscriptionGroups.getForClient(userId);

    return response;
  },
);

export const subscribeFreemium = createAsyncThunk(
  "payment/subscribeFreemium",
  async (data: { trainerId: string }, { getState, dispatch }) => {
    const { trainerId } = data;
    const state = getState() as RootState;

    const subOptions = selectSubscriptionOptions(state);
    const selectedOption = selectedPlan(state);

    // Hardcoded for freemium clients
    const freeTrialDays = 14;

    if (
      !state.client.client?.phone_number ||
      !subOptions ||
      !state.payment.subscriptionGroup
    ) {
      throw new Error(
        `Missing information on payment ${JSON.stringify({
          phoneNumber: state.client.client?.phone_number,
          subOptions,
        })}`,
      );
    }

    const response = await api.clients.subscribe(state.client.client.user_id!, {
      subscriptionGroupOptionId: subOptions[selectedOption].id,
      phoneNumber: state.client.client.phone_number,
      trainerId: trainerId,
      trialDays: freeTrialDays,
      sessionId: state.analytics.sessionId ?? "none",
      coupon: state.payment.stripeCoupon,
      shouldNotTrial: false,
      isInterestedInFsaHsa: undefined,
      analyticsProps: {
        $current_url: location.href,
        path: location.pathname,
        hostname: location.hostname,
      },
    });

    if (!response) {
      throw new Error(
        "finishPayment endpoint did not return any data when a response was expected",
      );
    }

    if ("user_id" in response) {
      dispatch(setClient(response));
    }

    return response;
  },
);

// Define a type for the slice state
interface PaymentState {
  subscriptionGroup: SubscriptionGroup | undefined;
  selectedSubOption: number;
  stripeCoupon: string | undefined;
  submitPaymentStatus:
    | "idle"
    | "loading"
    | "succeeded"
    | "succeeded-phone-exists"
    | "failed"
    | "account-error";
  submitPaymentError: string | undefined;
  codeID: string | undefined;
  trialDays: number;
  overrideSubscriptionOptions: SubscriptionGroupOption[] | null;
  isFree: boolean;
  forceNoCard: boolean;
  errors: {
    submitPaymentError: string | SerializedError | undefined;
    subscriptionOptionsError: SerializedError | undefined;
  };
}

// Define the initial state using that type
const initialState: PaymentState = {
  stripeCoupon: undefined,
  subscriptionGroup: undefined,
  selectedSubOption: 0,
  submitPaymentStatus: "idle",
  submitPaymentError: undefined,
  codeID: undefined,
  trialDays: 14,
  overrideSubscriptionOptions: null,
  isFree: false,
  forceNoCard: false,
  errors: {
    submitPaymentError: undefined,
    subscriptionOptionsError: undefined,
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetPayment: () => initialState,
    resetPaymentAttempt: (state) => {
      state.submitPaymentError = undefined;
      state.errors.submitPaymentError = undefined;
      state.submitPaymentStatus = "idle";
    },
    setSelectedSubOption: (state, action: PayloadAction<number>) => {
      state.selectedSubOption = action.payload;
    },
    setTrialDays: (state, action: PayloadAction<number>) => {
      state.trialDays = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchSubscriptionGroup.rejected, (state, action) => {
      console.log("Redux: Failed to get subscription");

      state.errors.subscriptionOptionsError = action.error;
    });
    builder.addCase(fetchSubscriptionGroup.fulfilled, (state, action) => {
      console.log("Redux: Got subscription info");

      const fetchedSubGroup = action.payload;

      if (fetchedSubGroup.options.length === 0) {
        state.errors.subscriptionOptionsError = {
          message: "No subscription options where returned by endpoint",
        };
        return;
      }

      state.subscriptionGroup = fetchedSubGroup;
    });
    builder.addCase(subscribeFreemium.pending, (state) => {
      state.submitPaymentStatus = "loading";
    });
    builder.addCase(subscribeFreemium.fulfilled, (state, action) => {
      console.log("Redux: Submitted payment");

      const response = action.payload;

      if ("user_id" in response) {
        state.submitPaymentStatus = "succeeded";
      } else {
        const { exists, accountError, state: clientState } = response;

        if (accountError) {
          state.submitPaymentStatus = "account-error";
          return;
        }

        if (exists) {
          state.submitPaymentStatus = "succeeded-phone-exists";
        }
      }
    });
    builder.addCase(subscribeFreemium.rejected, (state, action) => {
      state.submitPaymentStatus = "failed";
      state.submitPaymentError = action.error.message;
      state.errors.submitPaymentError = action.error;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPayment,
  resetPaymentAttempt,
  setSelectedSubOption,
  setTrialDays,
} = paymentSlice.actions;

export default paymentSlice.reducer;
/**
 * Return subOptions array if it exist and has at least one item in it, else returns undefined
 * @param state
 * @returns
 */
export const selectSubscriptionOptions = (
  state: RootState,
): SubscriptionGroupOption[] | undefined => {
  if (
    state.payment.overrideSubscriptionOptions &&
    state.payment.overrideSubscriptionOptions.length > 0
  ) {
    return state.payment.overrideSubscriptionOptions;
  }
  if (
    state.payment.subscriptionGroup &&
    state.payment.subscriptionGroup.options.length > 0
  ) {
    return state.payment.subscriptionGroup.options.filter(
      (option) => option.default,
    );
  }
  return undefined;
};

export const selectedPlan = (state: RootState) =>
  state.payment.selectedSubOption;
