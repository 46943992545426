import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getSanitizedTrainerName } from "@trainwell/features";
import type { Trainer } from "@trainwell/types";
import DialogTitle from "src/components/materialWrappers/DialogTitle";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

type Props = {
  isOpen: boolean;
  trainer: Trainer;
  handleClose: () => void;
  selectTrainerHandler: (trainerID: string) => void;
  chipData: { label: string; color: string; icon: JSX.Element }[];
};

export default function CoachProfileDialog({
  isOpen,
  trainer,
  handleClose,
  selectTrainerHandler,
  chipData,
}: Props) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      fullScreen={isPhone}
      maxWidth="md"
    >
      {isPhone ? (
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseRoundedIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {getSanitizedTrainerName(trainer)}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : (
        <DialogTitle onClose={handleClose}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                borderRadius: "40px",
                overflow: "hidden",
                mr: 2,
              }}
            >
              <img
                src={trainer.headshot_url_hd}
                width={80}
                height={80}
                alt={getSanitizedTrainerName(trainer)}
              />
            </Box>
            <Typography variant="h2">
              {getSanitizedTrainerName(trainer)}
            </Typography>
          </div>
        </DialogTitle>
      )}
      <DialogContent>
        {isPhone && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
              my: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderRadius: "40px",
                overflow: "hidden",
              }}
            >
              <img
                src={trainer.headshot_url_hd}
                width={80}
                height={80}
                alt={getSanitizedTrainerName(trainer)}
              />
            </Box>
            <Button
              onClick={() => {
                selectTrainerHandler(trainer.trainer_id);
                handleClose();
              }}
            >
              {"Partner with " + trainer.first_name}
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            listStyle: "none",
            p: 0,
            m: 0,
            mb: 4,
          }}
          component="ul"
        >
          {chipData.map((data) => {
            return (
              <ListItem key={data.label}>
                <Chip
                  icon={data.icon}
                  label={data.label}
                  sx={{
                    backgroundColor: data.color,
                    color: (theme) => theme.palette.text.primary,
                    ".MuiChip-iconColorDefault": {
                      color: (theme) => theme.palette.text.primary,
                    },
                    ".MuiChip-label": {
                      fontWeight: 700,
                    },
                  }}
                />
              </ListItem>
            );
          })}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }}>
            <Typography variant="h4" sx={{ mb: 1 }}>
              About {trainer.first_name}
            </Typography>
            <Typography sx={{ mb: 2 }}>{trainer.short_about}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
            <Typography variant="h4" sx={{ mb: 1 }}>
              Loves
            </Typography>
            <ul>
              {trainer.loves.map((love, i) => (
                <li key={i}>
                  <Typography>{love}</Typography>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
