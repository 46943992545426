import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Trainer } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";

export const fetchTrainer = createAsyncThunk(
  "trainer/fetchTrainer",
  async (trainerId: string) => {
    const response = await api.trainers.getOne(trainerId);

    return response;
  },
);

// Define a type for the slice state
interface TrainerState {
  trainer: Trainer | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: TrainerState = {
  trainer: undefined,
  status: "idle",
  error: undefined,
};

export const trainerSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    resetTrainer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrainer.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchTrainer.fulfilled, (state, action) => {
      console.log("Redux: Got trainer");
      state.status = "succeeded";

      const trainer = action.payload;

      state.trainer = trainer;
    });
    builder.addCase(fetchTrainer.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetTrainer } = trainerSlice.actions;

export default trainerSlice.reducer;
