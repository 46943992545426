import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

type Props = {
  date: Date;
  times: string[];
};

export default function AvailabilityDay({ date, times }: Props) {
  const hasAvailability = times.length > 0;

  return (
    <Box
      sx={{
        border: hasAvailability ? "1px solid #DDD7EA" : undefined,
        background: hasAvailability ? undefined : "#EEE",
        borderRadius: "8px",
        padding: "8px",
        justifyContent: "start",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          marginBottom: "8px",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {format(date, "EEE, MMM do")}
        </Typography>
      </Box>
      {times.map((time) => (
        <Typography sx={{ whiteSpace: "nowrap" }} variant="body2" key={time}>
          {time}
        </Typography>
      ))}
    </Box>
  );
}
