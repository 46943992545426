import { Box, Stack, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { useAppSelector } from "src/hooks/stateHooks";
import InvoiceItem from "./InvoiceItem";

type Props = {
  sx?: SxProps<Theme>;
};

export default function Invoices({ sx = [] }: Props) {
  const invoices = useAppSelector((state) => state.billing.invoices);

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Invoices
      </Typography>
      <Stack direction="column" spacing={1}>
        {invoices?.map((invoice, i) => {
          return <InvoiceItem invoice={invoice} key={invoice.id} />;
        })}
      </Stack>
    </Box>
  );
}
