import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Typography,
  alpha,
} from "@mui/material";
import type { Coupon } from "@trainwell/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat, showChat } from "src/lib/frontChat";
import { formatCentsToDollars } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { renewPlan, selectSubscriptionOptions } from "src/slices/billingSlice";
import { fetchTrainer } from "src/slices/trainerSlice";

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function RenewDialog({ open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const subscriptionOptions = useAppSelector(selectSubscriptionOptions);
  const [selectedPlan, setSelectedPlan] = useState(1);
  const client = useAppSelector((state) => state.client.client);
  const trainer = useAppSelector((state) => state.trainer.trainer);
  const trainerStatus = useAppSelector((state) => state.trainer.status);
  const couponId = useAppSelector((state) => state.billing.newCouponId);
  const [coupon, setCoupon] = useState<Coupon | null>(null);

  const currentSubscription = subscriptionOptions?.at(selectedPlan);

  let nextBillPrice = currentSubscription?.monthly_price ?? 0;

  if (currentSubscription?.interval === "month") {
    nextBillPrice =
      currentSubscription.monthly_price * currentSubscription.interval_count;
  } else if (currentSubscription?.interval === "year") {
    nextBillPrice =
      currentSubscription.monthly_price *
      currentSubscription.interval_count *
      12;
  }

  let discountAmountOff: number | null = null;

  if (coupon) {
    if (coupon.amount_off) {
      discountAmountOff = coupon.amount_off;
    } else if (coupon.percent_off) {
      discountAmountOff = nextBillPrice * coupon.percent_off;
    }
  }

  useEffect(() => {
    if (client?.trainer_id && trainerStatus === "idle") {
      dispatch(fetchTrainer(client.trainer_id));
    }
  }, [client?.trainer_id, trainerStatus, dispatch]);

  useEffect(() => {
    if (!coupon && couponId) {
      api.coupons.findOne(couponId).then((data) => {
        setCoupon(data.coupon);
      });
    }
  }, [couponId, coupon]);

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  function handleClose() {
    onClose();

    setSubmitting(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (submitting) {
          return;
        }

        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Renew plan</DialogTitle>
      <DialogContent>
        {subscriptionOptions.map((subOption, i) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 1,
            }}
            key={i}
          >
            <Box display="flex" alignItems="center">
              <Radio
                checked={selectedPlan === i}
                onChange={() => {
                  setSelectedPlan(i);
                }}
                value={i}
              />
              <Typography>{subOption.title}</Typography>
            </Box>
            <Box sx={{ textAlign: "end" }}>
              <Typography>${subOption.monthly_price}/mo</Typography>
              <Typography variant="caption">{subOption.details}</Typography>
            </Box>
          </Box>
        ))}
        {!client?.account.dashboard.date_onboarded && (
          <Typography
            sx={{
              textAlign: "right",
              fontWeight: "bold",
              color: "green",
            }}
          >
            Your 14 day free trial starts when you call your trainer
          </Typography>
        )}
        {discountAmountOff && (
          <Typography
            sx={{
              textAlign: "right",
              fontWeight: "bold",
              color: "green",
            }}
          >
            Discount Applied: {formatCentsToDollars(discountAmountOff)} off{" "}
            {coupon?.duration === "once"
              ? "first payment"
              : coupon?.duration === "forever"
                ? "every payment forever"
                : `every payment for ${coupon?.duration_in_months} months`}
          </Typography>
        )}
        {!trainer?.is_employee && (
          <Card
            variant="outlined"
            sx={{
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              p: 1,
              my: 2,
              display: "flex",
            }}
          >
            <WarningRoundedIcon color="primary" sx={{ mr: 1 }} />
            <Typography>
              Your trainer, {trainer?.first_name}, is no longer with trainwell.
              You&apos;ll be asked to choose someone new after renewing.
            </Typography>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleClose();
          }}
          disabled={submitting}
        >
          Close
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => {
            setSubmitting(true);

            if (!subscriptionOptions) {
              return;
            }

            dispatch(
              renewPlan(subscriptionOptions[selectedPlan].stripe_price_id),
            ).then(() => {
              setSubmitting(false);

              if (trainer?.is_employee) {
                handleClose();
              } else {
                navigate("/change-coach");
              }
            });
          }}
          loading={submitting}
        >
          Renew
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
