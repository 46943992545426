import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { Navigate } from "react-router";
import LoadingPage from "src/components/misc/LoadingPage";
import WithAuth from "src/components/misc/WithAuth";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { fetchBillingDetails } from "src/slices/billingSlice";
import TippingOptions from "./TippingOptions";

export default function TippingPage() {
  const dispatch = useAppDispatch();
  const billingStatus = useAppSelector((state) => state.billing.status);
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    if (billingStatus === "idle" && client?.user_id) {
      dispatch(fetchBillingDetails(client.user_id));
    }
  }, [billingStatus, dispatch, client?.user_id]);

  if (billingStatus === "loading") {
    return <LoadingPage message="Loading billing details" />;
  }

  if (
    client &&
    client.account.membership.state !== "active" &&
    client.account.membership.state !== "past_due"
  ) {
    return <Navigate to="/" />;
  }

  return (
    <WithAuth>
      <Box
        sx={{
          py: 3,
          px: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 4 },
          maxWidth: "md",
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Tip your trainer!
        </Typography>
        <TippingOptions />
      </Box>
    </WithAuth>
  );
}
