import { Container, Typography } from "@mui/material";

export default function Done() {
  return (
    <Container maxWidth="sm">
      <Typography variant="h1" sx={{ mb: 4 }}>
        Successfully changed trainer!
      </Typography>
    </Container>
  );
}
