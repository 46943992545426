import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// import { trackEvent } from "data/analyticsSlice";
// import { useAppDispatch, useAppSelector } from "hooks/stateHooks";
// import { track } from "lib/btracking";
import { useEffect, useState } from "react";
export default function ErrorDialog({
  title,
  body,
  buttonCTA,
  onClick,
}: {
  title?: string;
  body?: string;
  buttonCTA?: string;
  onClick?: () => void;
}) {
  // const dispatch = useAppDispatch();
  // const state = useAppSelector((state) => state);
  const [_, setDisplayed] = useState(false);
  // useEffect(() => {
  //   if (displayed) {
  //     dispatch(
  //       trackEvent({
  //         event_type: "error_dialog_shown",
  //         event_content: {
  //           path: location.pathname,
  //           url: location.href,
  //           state: state,
  //           location: location,
  //         },
  //       })
  //     );
  //     track("Error Dialog Show", {
  //       path: location.pathname,
  //       url: location.href,
  //     });
  //   }
  // }, [displayed]);

  useEffect(() => {
    setTimeout(() => {
      setDisplayed(true);
    }, 400);
  }, []);

  return (
    <Dialog
      open={true}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClick}>
          {buttonCTA}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ErrorDialog.defaultProps = {
  title: "Something went wrong!",
  body: "Please contact our support team for help at support@trainwell.net or through the chat at the bottom right of the screen.",
  buttonCTA: "Reload app",
  onClick: function () {
    document.cookie = "; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
    location.pathname = "/";
  },
};
