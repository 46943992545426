import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { hideChat, showChat } from "src/lib/frontChat";
import { formatCentsToDollars } from "src/lib/misc";
import { endPause } from "src/slices/billingSlice";

type Props = {
  planId: string;
  open: boolean;
  onClose: () => void;
};

export default function PauseEndDialog({ planId, open, onClose }: Props) {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const plan = useAppSelector((state) =>
    state.billing.plans?.find((plan) => plan.id === planId),
  );
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    if (open) {
      hideChat();
    } else {
      showChat({
        clientName: client?.full_name ?? "",
        userId: client?.user_id ?? "",
      });
    }
  }, [open]);

  function handleClose() {
    onClose();

    setSubmitting(false);
  }

  if (!plan) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (submitting) {
          return;
        }

        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Resume plan</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          By resuming you will be charged{" "}
          {formatCentsToDollars(plan.price ?? 0)} immediately and will regain
          access to the trainwell app. Your billing period will reset to today.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            handleClose();
          }}
          disabled={submitting}
        >
          Close
        </Button>
        <LoadingButton
          onClick={() => {
            setSubmitting(true);

            dispatch(endPause(plan.id)).then(() => {
              handleClose();
            });
          }}
          loading={submitting}
        >
          Resume
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
