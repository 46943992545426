import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useAppSelector } from "src/hooks/stateHooks";

export default function DownloadAppPage() {
  const theme = useTheme();
  const client = useAppSelector((state) => state.client.client);
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));
  const chosenOS = client?.device_platform ?? "ios";
  const trainer = useAppSelector((state) => state.trainer.trainer);

  useEffect(() => {
    localStorage.clear();
  }, []);

  let subtext = "";

  if (client?.last_login_date) {
    subtext = `We can't wait to join you on this new journey! You can expect ${trainer?.first_name} to reach out soon.`;
  } else {
    subtext = `We can't wait to join you on this new journey! You can expect ${trainer?.first_name} to reach out soon. Download the app to meet them.`;
  }

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          height: "75vh",
          justifyContent: { xs: "start", md: "space-between" },
          alignItems: "center",
          maxWidth: { sm: "sm", md: "md" },
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "430px", sm: "500px" },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              mb: 0,
              width: "100%",
              textAlign: "left",
            }}
          >
            Congrats on Investing in Your Fitness!
          </Typography>
          <Box
            sx={{
              display: { sm: "flex", md: "none" },
              width: { xs: "90vw", sm: "300px" },
              height: { xs: "200px", sm: "300px" },
              position: "relative",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <img
              src="/assets/copilot-done-page.svg"
              alt="movement illustration"
              width={"100%"}
              height={"100%"}
            />
          </Box>
          <Typography variant="subtitle1" sx={{ my: 2, textAlign: "left" }}>
            {subtext}
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: { xs: "center", md: "left" },
              mt: { xs: 5, md: 3 },
            }}
          >
            {chosenOS === "ios" && (
              <a
                href={
                  "https://apps.apple.com/us/app/deltatrainer-fitness-training/id1466445684"
                }
              >
                {!isPhone && (
                  <Box sx={{ mr: 4, display: "inline" }}>
                    <img
                      src="/assets/qr-codes/download-ios.svg"
                      alt="QR code to app store"
                      height={90}
                      width={90}
                    />
                  </Box>
                )}
                <img
                  src="/assets/download-on-the-app-store.svg"
                  alt="Link to app store"
                  height={90}
                  width={170}
                />
              </a>
            )}
            {chosenOS === "android" && (
              <a
                // style={{ marginLeft: 25 }}
                href={
                  "https://play.google.com/store/apps/details?id=com.mycopilot.copilotapp"
                }
              >
                {!isPhone && (
                  <Box sx={{ mr: 4, display: "inline" }}>
                    <img
                      src="/assets/qr-codes/download-android.svg"
                      alt="QR code to play store"
                      height={90}
                      width={90}
                    />
                  </Box>
                )}
                <img
                  src="/assets/google-play-store.svg"
                  alt="Link to play store"
                  height={90}
                  width={185}
                />
              </a>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            width: { xs: "90vw", sm: "300px" },
            height: { xs: "200px", sm: "300px" },
            position: "relative",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <img
            src="/assets/copilot-done-page.svg"
            alt="movement illustration"
            width={"100%"}
            height={"100%"}
          />
        </Box>
      </Container>
    </>
  );
}
