import { InputAdornment, MenuItem, TextField } from "@mui/material";

interface NativeSelectorProps {
  value: number | string | null;
  name: string;
  label: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  error: boolean | undefined;
  helperText: string | false | undefined;
  color: "primary" | "secondary" | undefined;
  onClick: () => void;
  options?: {
    select?: boolean;
    max?: number;
    min?: number;
  };
}

export default function NativeSelector({
  value,
  name,
  label,
  onBlur,
  onChange,
  onClick,
  error,
  helperText,
  options,
  color,
}: NativeSelectorProps) {
  const max = options?.max ?? 100;
  const select = options?.select ?? false;
  const min = options?.min ?? 1;
  return (
    <TextField
      fullWidth
      color={color}
      type="number"
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ mr: select ? 1.7 : 0 }} position="end">
            {label}
          </InputAdornment>
        ),
      }}
      select={select}
      name={name}
      value={value ?? ""}
      onClick={onClick}
      onBlur={onBlur}
      onChange={onChange}
      error={error}
      helperText={helperText}
    >
      {Array(max - min + 1)
        .fill(0)
        .map((_, idx) => (
          <MenuItem key={idx} value={idx + min}>
            {idx + min}
          </MenuItem>
        ))}
    </TextField>
  );
}
