import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import { ErrorDialog, NativeSelector } from "@trainwell/ui";

import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  payTip,
  resetTipping,
  selectTipPaymentError,
  selectTipPaymentStatus,
} from "src/slices/tippingSlice";
import * as yup from "yup";

type Props = {
  sx?: SxProps<Theme>;
};

const tipAmounts = [10, 20, 50];

const validationSchema = yup.object({
  tip: yup
    .number()
    .min(10, "Must be more than 10")
    .max(500, "Must be less than 500")
    .integer("No decimal")
    .typeError("Must be a number"),
});

export default function TippingOptions({ sx = [] }: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<number | undefined>(undefined);
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  const paymentStatus = useAppSelector(selectTipPaymentStatus);
  const paymentError = useAppSelector(selectTipPaymentError);
  const billingDetailsStatus = useAppSelector((state) => state.billing.status);

  const formik = useFormik({
    initialValues: {
      tip: "",
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: ({ tip }) => {
      const amount =
        selected !== undefined ? tipAmounts[selected] : Number(tip);
      console.log(amount);
      dispatch(payTip(amount));
      setSelected(undefined);
    },
  });

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Typography variant="h3" sx={{ mb: 1 }}>
        Pick an amount or enter your own.
      </Typography>
      <Grid container spacing={2} sx={{ my: 1 }}>
        {tipAmounts.map((amount, idx) => (
          <Grid item xs={3} sm={2} key={amount}>
            <Card
              sx={{
                height: 60,
                backgroundColor: (theme) =>
                  idx === selected ? theme.palette.secondary.main : undefined,
              }}
            >
              <CardActionArea
                onClick={() => {
                  setSelected(idx);
                  formik.setValues({ tip: "" });
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  height: "100%",
                  p: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 16,
                    flex: 1,
                    lineHeight: "normal",
                  }}
                >
                  ${amount}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
        <Grid item xs={3} sm={2}>
          <NativeSelector
            label="$"
            onClick={() => {
              setSelected(undefined);
            }}
            color="secondary"
            name="tip"
            value={formik.values.tip}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.tip && Boolean(formik.errors.tip)}
            helperText={formik.touched.tip && formik.errors.tip}
          />
        </Grid>
      </Grid>
      <Typography variant="caption" sx={{ display: "block", my: 1 }}>
        A portion of the tip will be retained by trainwell to cover processing
        fees
      </Typography>
      <Button
        startIcon={<CelebrationRoundedIcon />}
        onClick={() => {
          formik.handleSubmit();
        }}
        disabled={paymentStatus === "loading"}
      >
        Tip trainer
      </Button>
      {paymentError === "no_payment_method" && (
        <ErrorDialog
          title="Payment method needed"
          body="Please add a default payment method to your account."
          buttonCTA="Add payment method"
          onClick={() => {
            navigate("/billing");
            dispatch(resetTipping());
          }}
        />
      )}
      {paymentError === "no_default_payment" && (
        <ErrorDialog
          title="Default payment method needed"
          body="Please add a default payment method to your account."
          buttonCTA="Add payment method"
          onClick={() => {
            navigate("/billing");
            dispatch(resetTipping());
          }}
        />
      )}
      {paymentError === "client_not_found" && (
        <ErrorDialog
          title="Some information is missing"
          body="This is unusual, please reload the app and try again."
        />
      )}
      {(paymentError?.startsWith("Could not post at url") ||
        paymentError === "undefined_response") && (
        <ErrorDialog
          title="Something went wrong"
          body="Something went wrong processing your payment. Please contact support for assistance."
          buttonCTA="Try again"
          onClick={() => {
            dispatch(resetTipping());
          }}
        />
      )}
      {billingDetailsStatus === "failed" && (
        <ErrorDialog
          title="Missing Billing Details"
          body="We encounterd an issue retreiving your billing details. Try again and if the problem persist please contact support for more assistance."
        />
      )}
      {paymentStatus === "succeeded" && (
        <ErrorDialog
          title="Success! Your trainer was tipped!"
          body=""
          buttonCTA="Close"
          onClick={() => {
            dispatch(resetTipping());
          }}
        />
      )}
    </Box>
  );
}
