import * as Sentry from "@sentry/react";

export class TrainwellError extends Error {
  constructor(data?: {
    name?: string;
    message?: string;
    extraData?: Record<string, unknown>;
    cause?: Error;
  }) {
    const { message, extraData, name } = data ?? {};
    // Passes errMessage to the Error super class,
    // similar to call new Error(errMessage).
    super(message ?? "Something went wrong", { cause: data?.cause });

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.name = name || "TrainwellError";

    if (extraData) {
      Sentry.setContext("Extra Info", extraData);
    }
  }
}
